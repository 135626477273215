import classNames from "classnames"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import React from "react"

import { getImageMetaData } from "../../utils/images"
import s from "./Img.module.scss"

function Img({ className, placeholderHidden = false, src, ...props }) {
  const imageMetaData = getImageMetaData(src)
  if (!imageMetaData) {
    return <div className={className} />
  }

  const { aspectRatio, base64, variants } = imageMetaData
  const {
    baseSrc,
    srcSet,
    baseSrcWebp,
    srcSetWebp,
    sizes,
  } = createSrcSetsAndSizes(variants)
  const fluid = {
    aspectRatio,
    base64,
    src: baseSrc,
    srcSet,
    srcWebp: baseSrcWebp,
    srcSetWebp,
    sizes,
  }

  return (
    <GatsbyImage
      className={classNames(s.image, className, {
        [s.placeholderHidden]: placeholderHidden,
      })}
      fluid={fluid}
      {...props}
    />
  )

  function createSrcSetsAndSizes(variants) {
    return Object.entries(variants).reduce(
      (
        { srcSet = "", srcSetWebp = "" },
        [breakpointWidth, { src, srcWebp, width }]
      ) => ({
        baseSrc: src,
        srcSet: srcSet + (srcSet ? ", " : "") + `${src} ${width}w`,
        baseSrcWebp: srcWebp,
        srcSetWebp:
          srcSetWebp + (srcSetWebp ? ", " : "") + `${srcWebp} ${width}w`,
        sizes: `(max-width: ${breakpointWidth}px) 100vw, ${width}px`,
      }),
      {}
    )
  }
}

export default React.memo(Img)
