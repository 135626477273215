import imagesMeta from "../../static/images/meta.json"

export function getImageMetaData(src) {
  const imageMetaData = imagesMeta[src]

  if (!imageMetaData) {
    console.error("Optimized version of image not found: " + src)
    return
  }

  return imageMetaData
}
